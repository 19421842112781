import axiosDefault from "../../utils/api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { withoutEmptyProperties } from "../../utils";
import { customToast } from "../../utils/toasts";

const initialState = {
  userListMain: [],
  userListEmail: [],
  totalCount: 0,
  listEmailTotalCount: 0,
  listEmailIsLoading: false,
  queryParams: {
    page: 1,
    limit: 10,
    search: "",
  },
};

export const getOneIdUsersList = createAsyncThunk(
  "users/getOneIdUsersList",
  async (params, { rejectWithValue }) => {
    try {
      const response = await axiosDefault.get("/api/v1/users/oneid", {
        params: withoutEmptyProperties(params),
      });
      return { data: response.data, currentPage: params.page };
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const getUsersList = createAsyncThunk(
  "users/getUsersList",
  async (_, { rejectWithValue, getState }) => {
    const queryParams = getState().users.queryParams;
    try {
      const response = await axiosDefault.get("/api/v1/users", {
        params: withoutEmptyProperties(queryParams),
      });
      return response.data;
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const changeRole = createAsyncThunk(
  "users/changeRole",
  async ({ userId, role }, { rejectWithValue, dispatch }) => {
    try {
      await axiosDefault.patch("/api/v1/user/role", {
        id: userId,
        role: role,
      });
      dispatch(getUsersList());
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

export const addUser = createAsyncThunk(
  "users/addUser",
  async ({ idUser, role, params }, { dispatch, rejectWithValue }) => {
    try {
      await axiosDefault.post("/api/v1/user", { idUser, role });
      dispatch(getUsersList());
      dispatch(getOneIdUsersList(params));
      customToast({ text: "Пользователь успешно создан", type: "success" });
    } catch ({ response: { data } }) {
      return rejectWithValue(data?.errors?.[0]?.message ?? "Ошибка сервера");
    }
  }
);

export const deleteUser = createAsyncThunk(
  "users/deleteUser",
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      await axiosDefault.delete(`/api/v1/users/${id}`);
      dispatch(getUsersList());
    } catch (err) {
      rejectWithValue(err);
    }
  }
);

const usersSlice = createSlice({
  name: "usersSlice",
  initialState,
  reducers: {
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
  },
  extraReducers: {
    [getUsersList.fulfilled]: (state, action) => {
      state.userListMain = action.payload.items;
      state.totalCount = action.payload.totalCount;
    },
    [getOneIdUsersList.pending]: (state) => {
      state.listEmailIsLoading = true;
    },
    [getOneIdUsersList.fulfilled]: (state, action) => {
      const { data, currentPage } = action.payload;
      const newList =
        currentPage > 1 ? [...state.userListEmail, ...data.items] : data.items;
      state.userListEmail = newList;
      state.listEmailTotalCount = data.totalCount;
      state.listEmailIsLoading = false;
    },
    [addUser.rejected]: (state, action) => {
      customToast({ text: action.payload, type: "error" });
    },
  },
});

export const showUserListMain = (state) => state.users.userListMain;
export const showUserListEmail = (state) => ({
  list: state.users.userListEmail,
  totalCount: state.users.listEmailTotalCount,
  isLoading: state.users.listEmailIsLoading,
});
export const showTotalCount = (state) => state.users.totalCount;
export const showQueryParams = (state) => state.users.queryParams;

export const { setQueryParams } = usersSlice.actions;

export default usersSlice.reducer;
